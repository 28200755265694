import logo from './images/logo.png';
import learn_for_future from './images/learn_for_future.png';
import wave from './images/wave.png';
import wave2 from './images/wave_2.png';
import what_does from './images/what_does.png';
import safe from './images/safe.png';
import mode from './images/mode.png';
import yelow_triangle from './images/yelow_triangle.png';
import green_arrow from './images/green_arrow.png';
import yelow_arrow from './images/yelow_arrow.png';
import white_logo from './images/white_logo.png';
import linkedin from './images/linkedin.png';
import facebook from './images/facebook.png';
import instagram from './images/instagram.png'
import './App.css';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import { useState } from 'react';

function App() {
  return (
    <div className="App">
      <div className='container'>
        <div className='flex'>
          <div style={{flexGrow: 1}}>
            <img src={logo} alt="logo" className='logo' />
            <p className='yelow_info'>Старт навчання 1 вересня</p>
            <h2 className='container_title'>Розпочни карʼєру в ІТ!</h2>
            <p>Отримай можливість безкоштовно пройти курс із Front-end розробки</p>
            <div className='button'>реєстрація завершена</div>
          </div>
          <img src={learn_for_future} alt="learn_for_future" className='learn_for_future' />
        </div>
      </div>

      <img src={wave} alt="wave" />

      <div className='container' style={{marginTop: '-50px'}}>
        
        <div className='flex'>
          <img src={what_does} alt="what_does" className='what_does' />
          <Fade right>
            <div style={{flexGrow: 1}}>
              <h2 className='container_title'>Чим займається <br/> Front-end розробник? </h2>
              <p>
                Це програміст, який відповідає за зовнішній вигляд 
                сайтів, вебсервісів та мобільних додатків: верстку 
                шаблонів, оформлення тексту, клієнтську логіку 
                програмного продукту та взаємодію користувача з 
                інтерфейсом.
              </p>
            </div>
          </Fade>
        </div>
        
      </div>

      <div className='container'>
        <h2 className='container_title'>Що вчитимемо на курсі?</h2>
        <Fade bottom fraction={1}>
          <WhatLearnListItem text='Головні технології для front-end розробника: HTML, CSS та JavaScript '/>
          <WhatLearnListItem text='Особливості роботи браузерів'/>
          <WhatLearnListItem text='Робота з анімацією та різного роду ефектами, адаптивною версткою для будь-яких пристроїв'/>
          <WhatLearnListItem text='Застосовування JavaScript для динамічності та інтерактивності веб-сторінок'/>
          <WhatLearnListItem text='Тестування верстки веб-проектів'/>
          <WhatLearnListItem text='Знайомство з React та WordPress'/>
        </Fade>
      </div>

      <div className='background_wave'>
        <img src={wave2} alt="wave2" className='wave2'/>
        <img src={safe} alt="safe" className='safe'/>
        <img src={mode} alt="mode" className='mode'/>

        <h2 className='container_title white align_center'>Чому цей курс?</h2>
        <div className='card_list'>
          <Bounce left>
            <WhatThisCurse 
              title="Стабільна робота"
              text="В умовах надзвичайних обставин залишається дуже мало сфер діяльності, які можуть встояти та 
              запропонувати вам стабільність. ІТ - саме та галузь, де цікаво, безпечно та перспективно. 
              Працюйте в офісі або вдома з будь-якої точки світу."
            />
          </Bounce>
          <Bounce right>
            <WhatThisCurse 
              title="Затребувана спеціальність"
              text="Кількість пропозицій роботи для front- end розробників згідно з даними видання DОU за 
              минулий рік збільшилася майже вдвічі!"
            />
          </Bounce>
          <Bounce left>
            <WhatThisCurse 
              title="Перший досвід"
              text="Під час навчання ви будете виконувати практичні завдання, які згодом можна використати для портфоліо."
            />
          </Bounce>
          <Bounce right>
            <WhatThisCurse 
              title="Оптимальний старт для кар’єри в IT"
              text="За умови старанного виконання всіх завдань та успішного проходження курсу, ти зможеш претендувати на 
              стажування в ІТ-компанії PettersonApps вже через 14 тижнів!"
            />
          </Bounce>
        </div>
      </div>

      <div className='container'>
        <div className='flex' style={{flexDirection: 'column'}}>
          <Fade bottom>
            <div className='card_with_list float_right'>
              <h2 className='container_title'>Формат курсу </h2>
              <CardWithListItem text="Лекції в прямому ефірі (запис зберігається)" />
              <CardWithListItem text="Практичні завдання " />
              <CardWithListItem text="Чат-підтримка в Discord " />
              <CardWithListItem text="Курсовий проєкт (опційно) " />
              <CardWithListItem text="Тестування" />
              <CardWithListItem text="Матеріали для самостійного опрацювання" />
              <CardWithListItem text="Необмежений доступ до курсу " />
              <CardWithListItem text="Сертифікат" />
            </div>
          </Fade>
          <Fade bottom>
            <div className='card_with_list float_left'>
              <h2 className='container_title'>Лектор</h2>
              <CardWithListItem text="Євген Андріканич - web- розробник, фрілансер, відеоблогер" />
              <CardWithListItem text="Досвід роботи у сфері ІТ — понад 10 років" />
              <CardWithListItem text="Працював над створенням +1000 веб продуктів" />
              <CardWithListItem text="Понад 600 слухачів успішно завершили його курси з верстки сайтів" />
            </div>
          </Fade>
          <Fade bottom>
            <div className='card_with_list float_right' style={{paddingTop: '30px'}}>
              <CardWithListItem text="Тривалість курсу: 14 тижнів - 30 уроків " />
              <CardWithListItem text="Періодичність: двічі на тиждень по 3 години (четвер та неділя, 16:00 - 19:00)" />
              <CardWithListItem text="Старт навчання: 1 вересня 2022 р." />
            </div>
          </Fade>
        </div>
      </div>
      
      {/* <div className='container' style={{marginTop: '200px'}}>
        <h2 className='container_title align_center'>Реєстрація завершиться через</h2>
        <br/>
        <br/>
        <Countdown
          date={new Date('2022-08-24T23:59:59')}
          renderer={Timer}
        />
        <a href="https://forms.gle/Mnk4AcYJR53qD53B9" target="_blank" rel="noreferrer">
          <div className='button round'>реєстрація</div>
        </a>
      </div> */}

      <div className='container' style={{marginTop: '200px'}}>
        <h2 className='container_title align_center'>Залишились ще питання?</h2>
        <br/>
        <Fade left fraction={1}>
        <Acardion title="Скільки коштує навчання?" text={(<p>У рамках проекту Safe Mode, навчання на курсі front-end оплачується компанією PettersonApps. Тому участь в благодійній освітній ініціативі безкоштовна.</p>)} />
        <Acardion title="Скільки триватиме навчання?" text={(<p>Програма курсу розрахована на 14 тижнів. Це приблизно 4 місяці</p>)} />
        <Acardion title="Після реєстрації минуло кілька днів, але відповіді від менеджерів ще немає. Що робити?" text={(<p>Таке можливо, якщо в заявці вказана неправильна електронна адреса чи номер мобільного.<br/>У випадку, якщо твоя заявка не пройшла попереднього відбору, ти отримаєш листа із порадами від наших фахівців.</p>)} />
        <Acardion title="Як проходить процес прийому на курс?" text={(<p>Для навчання на курсі тобі потрібно пройти відбір. Він складається з наступних кроків:<br/><br/>1. Заповни та надішли форму заявки<br/><br/>2. У разі проходження попереднього відбору, з тобою сконтактує наш менеджер для проходження онлайн інтерв’ю, на якому ти розкажеш про свій досвід та очікування від курсу. Також ми дамо декілька технічних завдань та оцінимо твій рівень англійської мови.<br/><br/>3.Після успішного проходження онлайн інтервʼю, наші менеджери надішлють тобі запрошення на навчання із подальшими інструкціями.</p>)} />
        <Acardion title="Який графік курсу? Що мені потрібно буде робити?" text={(<p>Навчання на курсі буде проводитися онлайн щочетверга та неділі, з 16:00 по 19:00. Якщо ти не матимеш можливості приєднатися до групи в прямому ефірі, тобі завжди будуть доступні відеозаписи лекцій.<br/><br/>Впродовж курсу ти будеш дивитися вебінари та виконувати практичні завдання. Лектор та ментори перевірятимуть домашні завдання, даватимуть відповіді на твої запитання у спільному та приватному чатах в Discord</p>)} />
        <Acardion title="Я не маю технічної освіти, чи можу я записатися на курс?" text={(<p>Звичайно! Наш курс розрахований і на новачків. Більшість студентів не мають технічної освіти. Лектор, Євген Андріканич навчить тебе всім основам і допоможе у всьому.</p>)} />
        <Acardion title="Мені більше ніж 50 років, чи можу я подати заявку на навчання?" text={(<p>Ми не маємо обмежень за віком. IT — одна з найбільш недискримінаційних галузей. Ми розуміємо що починати з нуля може бути дуже важко, але ментори курсу будуть постійно з тобою на звʼязку</p>)} />
        <Acardion title="Ким я зможу працювати після закінчення Frontend курсу?" text={(<p>Випускники напрямку Front-end найчастіше отримують першу роботу на посаді Junior Front-end Developer.</p>)} />
        <Acardion title="Safe Mode допомагає з працевлаштуванням?" text={(<p>За умови старанного виконання всіх завдань та успішного проходження курсу, ти зможеш претендувати на стажування і, в подальшому на працевлаштування в ІТ-компанії PettersonApps.</p>)} />
        </Fade>
      </div>

      <div className='footer'>
        <img src={white_logo} alt="white_logo" />
        <div>
          <div className='links'>
            <a href="https://www.facebook.com/pettersonapps.company/"><img src={facebook} alt="facebook" /></a>
            <a href="https://www.instagram.com/petterson.apps/"><img src={instagram} alt="instagram" /></a>
            <a href="https://www.linkedin.com/company/pettersonapps/"><img src={linkedin} alt="linkedin" /></a>
          </div>
          <p className='email'>safe_mode@pettersonapps.com</p>
        </div>
      </div>
    </div>
  );
}

const WhatLearnListItem = ({text}) => (
  <div className='list_item'>
    <span className='list_dot'/>
    <div className='list_text_container'>
      <p>{text}</p>
    </div>
  </div>
)

const WhatThisCurse = ({title, text}) => (
  <div className='card_item'>
    <h3 className='card_title'>{title}</h3>
    <p>{text}</p>
  </div>
)

const CardWithListItem = ({text}) => (
  <div className='card_with_list_item'>
    <img src={yelow_triangle} alt="yelow_triangle" className='yelow_triangle' />
    <p>{text}</p>
  </div>
)

// const Timer = ({days, hours, minutes, seconds}) => (
//   <Fade top fraction={.1} cascade>
//   <div className='timer'>
    
//       <div className='timer_item'>
//         <div className='time_value'>{days}</div>
//         <div className='time_label'>ДНІВ</div>
//       </div>
//       <div className='timer_item'>
//         <div className='time_value'>{hours}</div>
//         <div className='time_label'>ГОДИН</div>
//       </div>
//       <div className='timer_item'>
//         <div className='time_value'>{minutes}</div>
//         <div className='time_label'>ХВИЛИН</div>
//       </div>
//       <div className='timer_item'>
//         <div className='time_value'>{seconds}</div>
//         <div className='time_label'>СЕКУНД</div>
//       </div>
    
//   </div>
//   </Fade>
// )

const Acardion = ({title, text}) => {
  const [open, setOpen] = useState(false);
  return (
    <div className='acardion_container' onClick={() => setOpen(!open)}>
      <div className='acardion_title'>
        <span>{title}</span>
        {!open ? (
          <img src={green_arrow} alt="green_arrow" />
        ) : (
          <img src={yelow_arrow} alt="yelow_arrow" />
        )}
      </div>
      <div className={open ? 'acardion_content' : 'acardion_content close_acardion' }>
        {text}
      </div>
    </div>
  )
}

export default App;
